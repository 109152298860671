import React, { Fragment } from 'react'

import { RawHtml } from '../../../index'
import OptionCard from './OptionCard'

export default function FeedingRecommendation(props) {
  const recommendation = props.recommendation
  if (!recommendation) {
    return null
  }
  const productType = props.productType

  let output = ''
  switch (productType) {
    case 'bundle':
      output = Object.keys(recommendation).map((key, index) => {
        const selections = recommendation[key]
        return (
          <div className="option-wrapper" key={key}>
            <span
              className="text-left text-dark font-weight-semi-bold d-block mb-2"
              key={index}
            >
              {key}
            </span>
            {Object.keys(selections).map((selectionId, index) => {
              const selection = selections[selectionId]
              if (selection.content?.length) {
                const content = <RawHtml html={selection.content} />
                return (
                  <Fragment key={index}>
                    <OptionCard heading={selection.heading} content={content} />
                  </Fragment>
                )
              }
            })}
          </div>
        )
      })
      break
    case 'simple':
      output = <RawHtml html={recommendation} />
      break
  }
  return output
}
