import { useEffect, useRef, useState } from 'react'
import { throttle } from '../../index'

/**
 * Returns an inner and outer ref as well as a sticky flag that is true until
 * the outer ref's top position + inner ref's height enters the screen from
 * the botton of the screen.
 *
 * Attach the outer ref to an element that scrolls with the page.
 * Attach the inner ref a child of the outer ref and do whatever with it.
 * The inner ref isn't strictly necessary for watching the scrolling but is
 * useful e.g. when you're watching the size of the inner element.
 *
 * @returns {{outerRef: React.MutableRefObject<null>, isSticky: boolean, innerRef: React.MutableRefObject<null>}}
 */
export default function useStickyScrollRefs() {
  const [isSticky, setSticky] = useState(false)
  const outerRef = useRef(null) // Track the position; must be scrolling with the page
  const innerRef = useRef(null) // E.g. apply fixed position here

  const doScroll = () => {
    const container = outerRef?.current

    if (container) {
      const outerBbox = container.getBoundingClientRect()
      const innerBbox = innerRef.current?.getBoundingClientRect()
      const innerHeight = innerBbox?.height || 0
      setSticky(outerBbox.top + innerHeight >= window.innerHeight)
    }
  }

  const onScroll = throttle(doScroll, 200)

  useEffect(() => {
    // Cannot use IntersectionObserver because position/size are updated
    // asynchronously, which may be too late in certain cases (e.g. when
    // scrolling too fast/slow near the element's threshold)
    window.addEventListener('scroll', onScroll)

    // Initial update; repeat a few times because of layout shifts when a page loads
    let counter = 0
    const interval = setInterval(() => {
      if (counter++ >= 5) {
        clearInterval(interval)
      }
      doScroll()
    }, 200) // Initial update
    return () => {
      window.removeEventListener('scroll', onScroll)
      clearInterval(interval)
    }
  }, [])

  return { isSticky, innerRef, outerRef }
}
