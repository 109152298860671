import React, { Component } from 'react'

import { RawHtml } from '../../index'
import { getFullUrl } from '../../../utils'
import { PricingContext } from '../../../utils/providers'

class Schema extends Component {
  static contextType = PricingContext

  constructor(props) {
    super(props)
  }

  render() {
    const { specialPrice } = this.context?.pricingContext || {}

    const productData = this.props.productData
    if (!productData) {
      return null
    }

    const price = specialPrice
    const productRatings = this.props.productRatings
    const description = productData.longdesc
      ? productData.longdesc.replace(/<[^>]*>?/gm, '')
      : ''

    const availability = productData.availability ? 'InStock' : 'OutOfStock'
    let eanCode = productData.sku

    if (productData.ean && productData.ean.length > 2) {
      eanCode = productData.ean[2]
    }
    eanCode = eanCode.toString()
    if (eanCode.length === 11) {
      eanCode = '0' + eanCode
    }
    if (eanCode.length === 12) {
      eanCode = '0' + eanCode
    }
    let review = ''
    if (productRatings && productRatings.count > 0) {
      review = {
        '@type': 'AggregateRating',
        ratingValue: productRatings.rating,
        reviewCount: productRatings.count,
      }
    }

    const { fullUrl: productUrl } = getFullUrl(productData.url[0])

    const schemaData = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      description: description,
      name: productData.title,
      image: productData.small_image,
      brand: {
        '@type': 'Brand',
        name: productData.manufacturer_name,
      },
      sku: productData.id,
      gtin13: eanCode,
      aggregateRating: review,
      offers: {
        '@type': 'AggregateOffer',
        priceCurrency: process.env.CURRENCY,
        highPrice: price,
        lowPrice: price,
        offerCount: 1,
        offers: [
          {
            '@type': 'Offer',
            url: productUrl,
            priceCurrency: process.env.CURRENCY,
            price: price,
            gtin13: eanCode,
            sku: productData.id,
            itemCondition: 'https://schema.org/NewCondition',
            availability: 'http://schema.org/' + availability,
          },
        ],
      },
    }
    const schemaJson = JSON.stringify(schemaData)

    return (
      <RawHtml element="script" type="application/ld+json" html={schemaJson} />
    )
  }
}

export default Schema
