import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Breadcrumbs } from '../../index'
import ImagesWithBadges from './ImagesWithBadges'
import SimpleProductRender from './SimpleProductRender'
import BundleProductRender from './BundleProductRender'
import AdditionalInfo from './AdditionalInfo'
import AboutProduct from './AboutProduct'
import { trackPDPView } from '../../../utils/Alphapet/tracking'

function ProductDetailInformation({ productData }) {
  const path = [
    {
      text: productData.name,
      link: productData.url[0],
    },
  ]

  useEffect(() => {
    if (productData) {
      trackPDPView(productData)
    }
  }, [])

  return (
    <>
      <section className="product-detail container mb-3 mb-lg-5">
        <Breadcrumbs items={path} />
        <div
          className="row"
          data-id={productData.id}
          data-sku={productData.sku}
        >
          <div className="col-12 col-lg-4">
            <ImagesWithBadges
              images={productData.images}
              badge={productData.badge}
            />
          </div>

          <div className="col-12 col-lg-8 product-detail-page mb-0 mb-md-4 mb-lg-0">
            <div className="row">
              <div className="col-12 mb-3">
                <AboutProduct productData={productData} />
              </div>
              {productData.product_type === 'simple' && (
                <SimpleProductRender productData={productData} />
              )}
              {productData.product_type === 'bundle' && (
                <BundleProductRender productData={productData} />
              )}
            </div>
          </div>
        </div>
      </section>
      <AdditionalInfo productData={productData} />
      {/* Makaira recommendations are now a premium feature and have been permanently disabled. Kept for future reference */}
      {/*isFeatureEnabled('product.recommendations') && (
        <Recommendations
          key={productData.id}
          productArt={productData.productart}
          productId={productData.id}
        />
      )*/}
    </>
  )
}

ProductDetailInformation.propTypes = {
  productData: PropTypes.object,
}

export default ProductDetailInformation
export { default as productDetailInformationVariants } from './variants.js'
