import { getProductPrice } from '../../index'

import { pushDataLayer, clearGA4Data } from './dataLayer'

/**
 * Tracks a Product Detail Page (PDP) view event in GTM and pushes relevant data to the dataLayer.
 *
 * @param {Object} productData - The data related to the viewed product.
 */
function trackPDPView(productData) {
  const price = getProductPrice(productData.priceinformation[0])
  const tagManagerDataLayerArgs = {
    dataLayer: {
      pageType: 'product',
      event: 'detailView',
      ga4: {
        currencyCode: process.env.CURRENCY,
        totalValue: price,
        items: [
          {
            item_id: productData.sku,
            item_name: productData.title,
            item_variant: productData.config_variable_a,
            price: price,
          },
        ],
      },
      ecommerce: {
        detail: {
          products: {
            id: productData.id,
            sku: productData.sku,
            price: productData.price,
            brand: productData.manufacturer_name,
            name: productData.title,
            variant: productData.config_variable_a,
            product_linie: productData.produkt_linie,
          },
        },
      },
    },
  }
  clearGA4Data()
  pushDataLayer(tagManagerDataLayerArgs)
}

export default trackPDPView
