import React from 'react'
import classNames from 'classnames'

export default class InhaltsStoffe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpened: false,
    }
  }

  onClick = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    })
  }

  render() {
    const headerClass = classNames('option-heading', {
      collapsed: this.state.isOpened,
    })
    const contentClass = classNames('option-content py-3', {
      'd-none': !this.state.isOpened,
    })
    return (
      <div className="option-card">
        <div className={headerClass} onClick={this.onClick}>
          <span>{this.props.heading}</span>
        </div>
        <div className={contentClass}>{this.props.content}</div>
      </div>
    )
  }
}
