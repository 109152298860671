import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'

import { fetchMakairaProducts } from '../../../utils'
import { useTranslation, withTranslation } from '../../../utils/providers'
import VariantsRenderer from './VariantsRenderer'

class PdpVariants extends Component {
  constructor(props) {
    super(props)
    this.state = {
      variantProducts: [],
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.productData.id !== prevProps.productData.id) {
      this.fetchData()
    }
  }

  fetchData() {
    const {
      productData: { groupedrelations },
      language,
    } = this.props
    if (groupedrelations) {
      fetchMakairaProducts(groupedrelations, language)
        .then((results) => {
          this.setState({
            variantProducts: results,
          })
        })
        .catch(console.error)
    }
  }

  render() {
    const { productData } = this.props
    const { variantProducts } = this.state
    if (!variantProducts?.length > 0) {
      return null
    }
    return (
      <VariantsRenderer
        variantLabel="LABEL_PRODUCT_CONFIG_A"
        variantLabelContent={productData.config_variable_a}
        variants={variantProducts}
        render="config_a"
        productData={productData}
      />
    )
  }
}

function BundlePdpVariants({ productData }) {
  const ctx = useTranslation()
  let groupedRelations = productData.groupedrelations
  if (isEmpty(groupedRelations)) {
    groupedRelations = [productData.id]
    productData.groupedrelations = groupedRelations
  }
  return <PdpVariants productData={productData} {...ctx} />
}

export default withTranslation(PdpVariants)
export { BundlePdpVariants }
