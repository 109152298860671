import { pushDataLayer } from './dataLayer'

/**
 * Tracks a Product Detail Page (PDP) info tab click event and sends data to the dataLayer.
 *
 * @param {string} tabLabel - The label of the clicked info tab.
 * @param {string} sku - The SKU of the product.
 */
function trackPDPInfoTabClick(tabLabel, sku) {
  if (tabLabel) {
    const tagManagerDataLayerArgs = {
      dataLayer: {
        event: 'pdpTabClick',
        pdpTab: {
          tab_label: tabLabel,
          sku: sku,
        },
      },
    }
    pushDataLayer(tagManagerDataLayerArgs)
  }
}

export default trackPDPInfoTabClick
