import React, { useState } from 'react'
import PropTypes from 'prop-types'

export default function Section({
  id,
  label,
  component: Component,
  props,
  onItemVisible,
}) {
  const [isExpanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!isExpanded)
    if (isExpanded && typeof onItemVisible === 'function') {
      onItemVisible(id)
    }
  }

  const inputId = 'accordion-' + id

  return (
    <div className="accordion__section" key={inputId}>
      <input
        type="checkbox"
        id={inputId}
        className="accordion__section-input"
        onChange={toggleExpanded}
      />
      <label className="accordion__section-label" htmlFor={inputId}>
        {label}
      </label>
      {isExpanded && (
        <div className="accordion__section-panel">
          <Component {...props} />
        </div>
      )}
    </div>
  )
}

Section.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  props: PropTypes.object.isRequired,
  onItemVisible: PropTypes.func,
}
