import { SnippetContent } from '../../index'
import React from 'react'

function DeliveryPromise(props) {
  const { availability } = props

  if (!availability) return null

  return (
    <div className="py-4">
      <SnippetContent snippetId="delivery_promise" />
    </div>
  )
}

export default DeliveryPromise
