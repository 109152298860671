import React, { Component, Fragment } from 'react'
import { RawHtml } from '../../../index'
import { TranslationComponent } from '../../../../utils'
import OptionCard from './OptionCard'

const LABELS = {
  composition: 'COMPOSITION',
  constituents: 'CONSTITUENTS',
  additives: 'ADDITIVES',
}

export default class Ingredients extends Component {
  constructor(props) {
    super(props)
  }

  renderSimple(ingredients) {
    const output =
      ingredients.content?.map(({ key, value }, index) => {
        if (!value) {
          return null
        }
        const selectionLabel = LABELS[key] || key
        return (
          <div className="row mb-3 pb-3" key={index}>
            <div className="col col-12 col-md-3">
              <TranslationComponent
                key={selectionLabel}
                text={selectionLabel}
              />
            </div>
            <div className="col col-12 col-md-9">
              <RawHtml html={value} />
            </div>
          </div>
        )
      }) || null

    return output
  }

  renderSection(selection) {
    const output =
      selection?.map(({ key, value }) => {
        if (!value) {
          return null
        }
        const selectionLabel = LABELS[key] || key
        return (
          <Fragment key={key}>
            <TranslationComponent key={selectionLabel} text={selectionLabel} />
            <div>
              <RawHtml html={value} />
            </div>
            <br />
          </Fragment>
        )
      }) || null
    return output
  }

  renderBundle(ingredients) {
    const output = Object.keys(ingredients).map((key) => {
      const selections = ingredients[key]
      return (
        <div className="option-wrapper" key={key}>
          <span className="text-left text-dark font-weight-semi-bold d-block mb-2">
            {key}
          </span>
          {Object.entries(selections).map(([selectionId, selection]) => (
            <OptionCard
              key={selectionId}
              heading={selection.label}
              content={this.renderSection(selection.content)}
            />
          ))}
        </div>
      )
    })
    return output
  }

  render() {
    const ingredients = this.props.ingredients
    if (!ingredients) {
      return null
    }
    const productType = this.props.productType
    let output = ''
    switch (productType) {
      case 'bundle':
        output = this.renderBundle(ingredients)
        break
      case 'simple':
        output = this.renderSimple(ingredients)
        break
    }
    return output
  }
}
