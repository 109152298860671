import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  dispatchPageNoScroll,
  dispatchPageScroll,
  ResizeContext,
  TranslationComponent,
} from '../../../utils'

class Dropdown extends React.Component {
  static contextType = ResizeContext

  constructor(props) {
    super(props)

    this.state = {
      isExpanded: false,
      selected: props.value ?? props.options[0]?.value,
    }
  }

  componentWillUnmount() {
    this.stopClickListener()
  }

  startClickListener = () => {
    window.addEventListener('click', this.hideWhenClickOutside)
  }

  stopClickListener = () => {
    window.removeEventListener('click', this.hideWhenClickOutside)
  }

  toggleExpanded = () => {
    const { breakpoints } = this.context || {}
    this.setState((prevState) => {
      // Stop the click listener when the dropdown is closed, start it otherwise
      if (prevState.isExpanded) {
        this.stopClickListener()
      } else {
        this.startClickListener()
      }
      // Ensure body scrolling is enabled when closing the dropdown,
      // allow for no scrolling only on mobile
      if (prevState.isExpanded) {
        dispatchPageScroll()
      } else if (breakpoints?.downMD) {
        dispatchPageNoScroll()
      }
      return {
        isExpanded: !prevState.isExpanded,
      }
    })
  }

  hideWhenClickOutside = (event) => {
    const { id } = this.props
    if (!event.target.closest(`#${id}`)) {
      this.setState({ isExpanded: false })
      this.stopClickListener()
      dispatchPageScroll()
    }
  }

  handleChange = ({ index, value }) => {
    this.setState({ isExpanded: false, selected: value }, () =>
      this.props.onChange({ index, value })
    )
    this.stopClickListener()
    dispatchPageScroll()
  }

  render() {
    const {
      id = '',
      name = '',
      label = '',
      options = [],
      anchor = 'left',
      className,
    } = this.props

    if (options.length == 0) return null

    const { isExpanded, selected } = this.state

    const dropdownClasses = classNames(className, 'dropdown', {
      ['dropdown--with-label']: label != '',
    })

    const listboxClasses = classNames('dropdown__listbox', {
      ['dropdown__listbox--visible']: isExpanded,
      ['dropdown__listbox--with-label']: label != '',
      [`dropdown__listbox--anchor-${anchor}`]: isExpanded,
    })

    const overlayClasses = classNames(
      'overlay z-index-dropdown d-sm-block d-md-none',
      {
        'overlay-visible': isExpanded,
      }
    )

    const selectedOption = options.find((o) => o.value == selected) ?? ''
    let i = 1
    return (
      <div id={id} className={dropdownClasses}>
        <button
          type="button"
          className="p-3 product-list__sorter-button"
          aria-haspopup="listbox"
          aria-expanded={isExpanded}
          onClick={this.toggleExpanded}
        >
          <span className="dropdown-desktop-label">{selectedOption.label}</span>
          <span className="dropdown-mobile-label text-uppercase">
            <TranslationComponent text="SORT_HEADING" />
          </span>
          <i className="cf-sort pl-2"></i>
        </button>
        <ul role="listbox" aria-labelledby={id} className={listboxClasses}>
          {options.map((option, index) => {
            const { label, value } = option

            const optionClasses = classNames('dropdown__option', {
              ['dropdown__option--selected']: value == selected,
            })

            return (
              <React.Fragment key={option.value}>
                {i === 1 && (
                  <li className="dropdown__heading">
                    <TranslationComponent text="SORT_HEADING" />
                  </li>
                )}
                <li
                  className={optionClasses}
                  onClick={() => this.handleChange({ index, value })}
                >
                  {label}
                  <span style={{ display: 'none' }}>{i++}</span>
                </li>
              </React.Fragment>
            )
          })}
        </ul>
        <div
          key="dropdownOverlay"
          className={overlayClasses}
          onClick={this.toggleExpanded}
        />

        {name && <input type="hidden" name={name} value={selected} />}
      </div>
    )
  }
}

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  anchor: PropTypes.oneOf(['left', 'right']),
  onChange: PropTypes.func.isRequired,
}

export default Dropdown
export { default as dropdownVariants } from './variants.js'
