import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function Tabs({
  className,
  items,
  onItemVisible,
  initialTab = 0,
}) {
  const [activeTab, setActiveTab] = useState(initialTab)
  const clazz = classNames('tabs__wrapper', className)

  return (
    <div className={clazz}>
      <div className="tabs container">
        {items.map(({ id, label, component: Component, props = {} }, index) => {
          const defaultCheckedValue = index === initialTab
          const inputId = 'tab-' + id

          const onChange = () => {
            setActiveTab(index)
            if (typeof onItemVisible === 'function') {
              onItemVisible(id)
            }
          }

          return (
            <Fragment key={inputId}>
              <input
                name="tabs"
                type="radio"
                id={inputId}
                className="tabs__input"
                value={inputId}
                defaultChecked={defaultCheckedValue}
                onChange={onChange}
              />
              <label htmlFor={inputId} className="tabs__label">
                {label}
              </label>

              {activeTab === index && (
                <div className="tabs__panel">
                  <Component {...props} />
                </div>
              )}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  className: PropTypes.string,
  sku: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      component: PropTypes.elementType,
      props: PropTypes.object,
    })
  ).isRequired,
  initialTab: PropTypes.number,
}
