import React from 'react'

import { useFeatureFlags } from '../../../../utils'
import ProductBadges from '../../ProductBadges'
import ImageGallery from './ImageGallery'

export default function ImagesWithBadges({ images, badge }) {
  const { isFeatureEnabled } = useFeatureFlags()
  return (
    <div className="product-image-gallery mb-4">
      {isFeatureEnabled('product.badges') && <ProductBadges badge={badge} />}
      <ImageGallery images={images} />
    </div>
  )
}
