import React, { Component } from 'react'
import { fetchMakairaProducts } from '../../../utils'
import { withTranslation } from '../../../utils/providers'
import VariantsRenderer from './VariantsRenderer'

class RelatedProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.productData.id !== prevProps.productData.id) {
      this.fetchData()
    }
  }

  fetchData() {
    const {
      productData: { relatedProducts },
      language,
    } = this.props
    if (relatedProducts) {
      fetchMakairaProducts(relatedProducts, language)
        .then((results) => {
          this.setState({
            products: results,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  render() {
    const { productData } = this.props
    const { products } = this.state

    if (products.length <= 1) return null
    return (
      <VariantsRenderer
        productData={productData}
        variantLabel="LABEL_PRODUCT_SIMILAR_PRODUCT"
        variantLabelContent={productData.name}
        variants={products}
      />
    )
  }
}
export default withTranslation(RelatedProducts)
