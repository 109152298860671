import React from 'react'

import ProductPrices from '../PriceInfo/ProductPrices'
import ProductPriceHint from '../PriceInfo/ProductPriceHint'
import { isAboProduct, useFeatureFlags } from '../../../utils'

import BuyBox from './BuyBox'
import PdpVariants from './PdpVariants'
import DeliveryBox from './DeliveryBox'
import RelatedProducts from './RelatedProducts'
import DeliveryPromise from './DeliveryPromise'

export default function SimpleProductRender({ productData }) {
  const { isFeatureEnabled } = useFeatureFlags()

  return (
    <>
      <div className="col-12 col-md-6">
        <ProductPrices
          productData={productData}
          classSpecialPrice="font-size-xl font-weight-semi-bold"
          classRegularPrice="d-inline-block ml-2 font-size-l"
          classUnitPrice="font-size-xxs d-block"
        />
        <ProductPriceHint className="font-size-xs" />
        <PdpVariants productData={productData} />
        {isFeatureEnabled('relatedProducts') && !productData.aktionen_apv && (
          <RelatedProducts productData={productData} />
        )}
      </div>
      <div className="col-12 col-md-6">
        <BuyBox productData={productData} disableVariants={true} />
        {isFeatureEnabled('abo') && isAboProduct(productData) && (
          <DeliveryBox productData={productData} />
        )}
        <DeliveryPromise availability={productData.availability} />
      </div>
    </>
  )
}
