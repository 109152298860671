import React from 'react'

import { fetchRatings } from '../../../utils'
import { withFeatureFlags } from '../../../utils/providers'

import ProductRating from '../ProductRating'
import Schema from './Schema'

class AboutProduct extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ratings: null,
    }
  }

  componentDidMount() {
    this.setRatings()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productData?.id !== this.props.productData?.id) {
      this.setRatings()
    }
  }

  setRatings = () => {
    const { productData } = this.props
    const products = [{ id: productData.id }]
    if (this.props.isFeatureEnabled('product.ratings')) {
      fetchRatings(products)
        .then((results) => {
          this.setState({
            ratings: results,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  render() {
    const { productData, isFeatureEnabled } = this.props
    const { ratings } = this.state
    const rating = ratings ? ratings[productData.id] : null
    return (
      <>
        <div className="product-detail-page__header d-md-flex align-items-md-center mb-3">
          <a
            className="font-size-xl font-weight-bold d-inline-block mr-3"
            href={productData.manufacturer_url}
          >
            {productData.manufacturer_name} {productData.produkt_linie}
          </a>
          {rating && isFeatureEnabled('product.ratings') && (
            <ProductRating
              productData={productData}
              ratingSummary={rating}
              ratingUrl={false}
            />
          )}
        </div>
        <p className="mb-1 font-size-m">{productData.name}</p>
        <Schema productRatings={rating} productData={productData} />
      </>
    )
  }
}

export default withFeatureFlags(AboutProduct)
