import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'

import { Image } from '../../..'
import { useTranslation } from '../../../../utils/providers'

export default function ImageGallery({ images }) {
  const [index, setIndex] = useState(0)
  const { t } = useTranslation()

  const responsive = {
    desktop: {
      breakpoint: { max: Number.MAX_VALUE, min: 1024 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 1023.98, min: 160 },
      items: 1,
    },
  }

  // When the number of images changes react-multi-carousel has to be
  // re-initialized to avoid an "invalid array length" error while "showDots"
  // is set to "true". Changing the react key forces that behaviour.
  // See also https://jira.epetworld.de/browse/MAG-1349
  const carouselKey = images.length

  return (
    <div className="product-image">
      <div className="image-to-show">
        <Image alt={t('PDP_PRODUCT_IMAGE_ALT')} srcFull={images[index]} />
      </div>
      <Carousel
        key={carouselKey}
        responsive={responsive}
        removeArrowOnDeviceType={['mobile']}
        className="product-thumbnails mt-3"
        showDots={false}
        renderDotsOutside={true}
      >
        {images.map((image, i) => {
          const onClick = () => setIndex(i)
          return (
            <Image
              onClick={onClick}
              key={`${image}-${i}`}
              className="image-large"
              alt={t('PDP_PRODUCT_IMAGE_ALT')}
              srcFull={image}
            />
          )
        })}
      </Carousel>
    </div>
  )
}
