import * as React from 'react'
import { debounce, loadReviewWidget } from '../../../../utils'
import { trackProductRatingClick } from '../../../../utils/Alphapet/tracking'

export default class Ratings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pushedProducts: [],
    }
  }

  updateReviews() {
    const { id, parent_sku, produktgruppe_apv, sku } = this.props.productData
    loadReviewWidget(id, parent_sku, produktgruppe_apv, sku)
  }

  handleScroll = debounce(() => {
    const { sku } = this.props.productData
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      const elements = document.getElementsByClassName('review-block')
      const defaultOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      }

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!this.state.pushedProducts.includes(entry.target.dataset.id)) {
              observer.unobserve(entry.target)
              this.setState({
                pushedProducts: [
                  ...this.state.pushedProducts,
                  entry.target.dataset.id,
                ],
              })
              trackProductRatingClick(sku)
            }
          }
        })
      }

      const observer = new IntersectionObserver(callback, defaultOptions)
      Object.values(elements).forEach((element) => {
        observer.observe(element)
      })
    }
  }, 200)

  componentDidMount() {
    this.updateReviews()
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate(prevProps) {
    const oldProductId = prevProps.productData.id
    const newProductId = this.props.productData.id
    if (oldProductId !== newProductId) {
      this.updateReviews()
    }
  }

  render() {
    const { productData } = this.props
    const name =
      productData.manufacturer_name +
      ' ' + //Contains manufacturer + product_linie
      productData.name +
      ' ' + //Name_APV value
      productData.config_variable_a //config variable a

    return (
      <div
        id="review-widget"
        data-product-id={productData.id}
        data-product-name={name}
        data-product-gtin=""
        data-product-ean=""
        data-url={productData.url[0]}
        data-imageurl={productData.images[0]}
        data-group-name={productData.parent_sku}
        data-product-attribute={productData.produktgruppe_apv}
        data-syndication-code={productData.sku}
      />
    )
  }
}
