import { pushDataLayer } from './dataLayer'

/**
 * Tracks a product rating click event and sends data to the dataLayer.
 *
 * @param {string} sku - The SKU of the product.
 */
function trackProductRatingClick(sku) {
  const tagManagerDataLayerArgs = {
    dataLayer: {
      event: 'productRating',
      rating: {
        sku: sku,
      },
    },
  }
  pushDataLayer(tagManagerDataLayerArgs)
}

export default trackProductRatingClick
