import React, { Fragment, useEffect, useState } from 'react'

import { Accordion, Tabs, RawHtml } from '../../../index'
import {
  useFeatureFlags,
  useMediaResize,
  useTranslation,
} from '../../../../utils/providers'
import { trackPDPInfoTabClick } from '../../../../utils/Alphapet/tracking'

import FeedingRecommendation from './FeedingRecommendation'
import Ingredients from './Ingredients'
import Ratings from './Ratings'

export default function AdditionalInfo({ productData }) {
  const {
    id: productId,
    product_type: productType,
    longdesc: descriptionText,
    inhaltsstoffe_data_only: ingredientsData,
    futterungsempfehlung_data_only: recommendationData,
  } = productData
  const { isFeatureEnabled } = useFeatureFlags()
  const { breakpoints, initialDevice } = useMediaResize()
  const { t } = useTranslation()
  const [isMobile, setMobile] = useState(initialDevice === 'mobile')

  useEffect(() => {
    setMobile(breakpoints.downMD)
  }, [breakpoints.downMD])

  const tabData = [
    {
      id: 'description',
      label: t('DESCRIPTION'),
      component: RawHtml,
      props: { html: descriptionText },
    },
    {
      id: 'ingredients',
      label: t('INGREDIENTS'),
      component: Ingredients,
      props: {
        ingredients: ingredientsData,
        productType,
      },
    },
    {
      id: 'recommendation',
      label: t('FEEDING_RECOMMENDATION'),
      component: FeedingRecommendation,
      props: {
        recommendation: recommendationData,
        productType,
      },
    },
  ]
  if (isFeatureEnabled('product.review')) {
    tabData.push({
      id: 'ratings',
      label: t('RATINGS'),
      component: Ratings,
      props: { productData },
    })
  }
  // Filter out tabs without data to avoid showing empty tabs.
  // 'ingredients' and 'recommendation' can have different types: array, boolean, object
  const filteredTabData = tabData.filter((tab) => {
    if (tab.id === 'ingredients') {
      const isNonEmptyArray =
        Array.isArray(ingredientsData) && ingredientsData?.length > 0
      const hasNonArrayValue =
        !Array.isArray(ingredientsData) && !!ingredientsData
      if (isNonEmptyArray || hasNonArrayValue) {
        return tab
      }
    } else if (tab.id === 'recommendation') {
      const isNonEmptyArray =
        Array.isArray(recommendationData) && recommendationData.length > 0
      const hasNonArrayValue =
        !Array.isArray(recommendationData) && !!recommendationData
      if (isNonEmptyArray || hasNonArrayValue) {
        return tab
      }
    } else {
      return tab
    }
  })

  const onItemVisible = ({ id }) => {
    trackPDPInfoTabClick(id, productData.sku)
  }

  // Make sure to provide a key to the fragment, so the tabs are re-rendered
  // for different products.
  return (
    <Fragment key={productId}>
      {isMobile ? (
        <Accordion
          className="mb-4 detailed-info"
          items={filteredTabData}
          onItemVisible={onItemVisible}
        />
      ) : (
        <Tabs
          className="detailed-info"
          items={filteredTabData}
          onItemVisible={onItemVisible}
        />
      )}
    </Fragment>
  )
}
