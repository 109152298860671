export default function loadReviewWidget(id, parentSku, grupApv, sku) {
  var customLoadEvent = new CustomEvent('reviewWidget', {
    detail: {
      type: 'init',
      productId: id,
      groupName: parentSku,
      productAttribute: grupApv,
      syndicationCode: sku,
    },
  })
  document.dispatchEvent(customLoadEvent)
}
