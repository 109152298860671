import React, { useEffect, useState } from 'react'

import QtySelect from '../QtySelect'
import ProductActionButton from '../ProductActionButton'
import {
  setCartWidgetOffset,
  useMediaResize,
  useStickyScrollRefs,
} from '../../../utils'

function BuyBox({ productData, disableVariants }) {
  const [qty, setQty] = useState(1)
  const { breakpoints } = useMediaResize()
  const { isSticky, innerRef, outerRef } = useStickyScrollRefs()

  useEffect(() => {
    const offset = isSticky ? innerRef?.current?.clientHeight : 0
    setCartWidgetOffset(offset)
  }, [isSticky, breakpoints.downMD, innerRef?.current])

  const actionBtn = (
    <ProductActionButton
      productData={productData}
      qty={qty}
      disableVariants={disableVariants}
      trackingData={{ origin: 'pdp' }}
    />
  )

  return (
    <div className="product-detail-page__action-box">
      <QtySelect
        isDisabled={!productData.availability}
        min={1}
        max={10}
        qty={qty}
        changeQty={setQty}
        type="inputBox"
      />
      {breakpoints.downMD ? (
        <div ref={outerRef}>
          <div ref={innerRef} className={isSticky ? 'fixed-bottom' : null}>
            {actionBtn}
          </div>
        </div>
      ) : (
        actionBtn
      )}
    </div>
  )
}

export default BuyBox
