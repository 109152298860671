import React from 'react'
import { sanitizeMarkdown, Link, ConditionalProductLink } from '../../index'
import { useTranslation } from '../../../utils'

export default function Breadcrumbs(props) {
  const { items } = props
  const { t } = useTranslation()
  return (
    <nav aria-label="breadcrumb" className="d-none d-md-block container">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link href="/" title={t('HOME_PAGE')}>
            {t('HOME_PAGE')}
          </Link>
        </li>

        {items?.map((item, index) => {
          const text = sanitizeMarkdown(item.text)
          return (
            <li key={index} className="breadcrumb-item">
              <ConditionalProductLink href={item.link} fallbackElement="span">
                {text}
              </ConditionalProductLink>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
