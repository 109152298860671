import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Section from './Section'

export default function Accordion({ className, items, onItemVisible }) {
  const clazz = classNames('accordion', className)

  return (
    <div className={clazz}>
      {items.map((item) => {
        return <Section key={item.id} onItemVisible={onItemVisible} {...item} />
      })}
    </div>
  )
}

Accordion.propTypes = {
  className: PropTypes.string,
  onItemVisible: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      component: PropTypes.elementType,
      props: PropTypes.object,
    })
  ).isRequired,
}
